import React from 'react';

const Volver = () => {
  const iframeSrc = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1854427536&color=%23c8b8b0&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true";
  const containerStyle = {
    fontSize: '10px',
    color: '#cccccc',
    lineBreak: 'anywhere',
    wordBreak: 'normal',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: 'Interstate, Lucida Grande, Lucida Sans Unicode, Lucida Sans, Garuda, Verdana, Tahoma, sans-serif',
    fontWeight: 100,
  };
  const linkStyle = {
    color: '#cccccc',
    textDecoration: 'none',
  };

  return (
    <div>
      <iframe
        title="Volver by Puchos"
        width="100%"
        height="300"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={iframeSrc}
      ></iframe>
      <div style={containerStyle}>
        <a href="https://soundcloud.com/puchos" title="Puchos" target="_blank" rel="noopener noreferrer" style={linkStyle}>
          Puchos
        </a> · 
        <a href="https://soundcloud.com/puchos/volver-mixed-and-mastered" title="Volver" target="_blank" rel="noopener noreferrer" style={linkStyle}>
          Volver
        </a>
      </div>
    </div>
  );
};

export default Volver;
