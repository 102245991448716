import React from 'react';
import Salar from './Imgs/thumbnail_bigger.png';
import Oracular from './Imgs/thumbnail_small.png';

export default function Games() {
    return (
        <div>

            <table className="games-table">
                <tbody>
                    <tr>
                        <td className="games-image-cell">
                            <img className="games-image" src={Salar} alt="Salar" />
                        </td>
                        <td className="games-text-cell">
                            <h1>El Salar</h1>
                            <p>A young flamingo is left behind during migration season. In search of Illapa, the Ancient God who might teach him to fly, he embarks on a journey of self-discovery and spirituality, inspired by Bolivian folklore.</p>
                            <a href='https://andre-figueroa.itch.io/el-salar'>Play the demo now on itch!</a>
                        </td>
                    </tr>
                    <tr>
                        <td className="games-image-cell">
                            <img className="games-image" src={Oracular} alt="Oracular" />
                        </td>
                        <td className="games-text-cell">
                            <h1>Oracular Spooktacular</h1>
                            <p>A short 2d pixel-art run-and-gun platformer about a kid that accidentally turns his whole town into zombies during Halloween Night. Now he's the only one that can turn everyone back to normal with a gun that has a kickback a bit too strong for him to handle.</p>
                            <a href='https://andre-figueroa.itch.io/oracular-spooktacular'>Play it now on itch!</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
