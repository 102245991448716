import React from 'react';
import Aguayo from "./Imgs/Aguayo Animado.gif";
import Cacho from "./Imgs/cacho.gif";
import Micro from "./Imgs/SEXO ALCOHOL Y CUMBIA .gif";
import Oso from "./Imgs/oso_cumbiero.gif";
import HablaConmigo from "./Imgs/Habla Conmigo Animado.gif";
import Guitar from "./Imgs/guitar_rotoscopy.gif";
import Pique from "./Imgs/PIXEL MACHO.gif";
import Ekeko from "./Imgs/ekeko.png";
import './styles.css'

export default function PixelArt() {
    return (
        <div>

            <div className='pixel-art-container'>
                <img className='pixel-art-pic' src={Micro} alt="Micro" />
                <img className='pixel-art-pic' src={Cacho} alt="Cacho" />
                <img className='pixel-art-pic' src={Aguayo} alt="Aguayo" />
                <img className='pixel-art-pic' src={Oso} alt="Oso" />
                <img className='pixel-art-pic' src={HablaConmigo} alt="Habla Conmigo" />
                <img className='pixel-art-pic' src={Guitar} alt="Guitar" />
                <img className='pixel-art-pic' src={Pique} alt="Pique" />
                <img className='pixel-art-pic' src={Ekeko} alt="Ekeko" />
            </div>
        </div>
    
    );
}
