import React from 'react';
import './styles.css'; 
import instagramBtn from './Imgs/instagramButton.png';
import itchBtn from './Imgs/itchButton.png';
import twitterBtn from './Imgs/twitterButton.png';
import youtubeBtn from './Imgs/youtubeButton.png';
import soundcloudBtn from './Imgs/soundcloudButton.png';
import spotifyBtn from './Imgs/spotifyButton.png';

export default function Footer() {
    return (
        <footer className="footer">
            <ul className="footer-btns">
                <li>
                    <a href="https://www.instagram.com/_andrefigueroa_/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramBtn} alt="Instagram" />
                    </a>
                </li>
                <li>
                    <a href="https://andre-figueroa.itch.io/el-salar" target="_blank" rel="noopener noreferrer">
                        <img src={itchBtn} alt="Itch" />
                    </a>
                </li>
                <li>
                    <a href="https://x.com/_sopa_de_mani_" target="_blank" rel="noopener noreferrer">
                        <img src={twitterBtn} alt="Twitter" />
                    </a>
                </li>
                <li>
                    <a href="https://www.youtube.com/channel/UCr8X74TSXilB7Dj4bGeX4ew" target="_blank" rel="noopener noreferrer">
                        <img src={youtubeBtn} alt="YouTube" />
                    </a>
                </li>
                <li>
                    <a href="https://soundcloud.com/user-580494394-294468329" target="_blank" rel="noopener noreferrer">
                        <img src={soundcloudBtn} alt="SoundCloud" />
                    </a>
                </li>
                <li>
                    <a href="https://open.spotify.com/artist/3COweCtNpbwwTwHx6jT5YN?si=dOxNoB0yQPS8EjohywZ_eQ" target="_blank" rel="noopener noreferrer">
                        <img src={spotifyBtn} alt="Spotify" />
                    </a>
                </li>
            </ul>
        </footer>
    );
}
