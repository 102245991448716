import React, { useState } from 'react';
import PixelArt from './PixelArt';
import Games from './Games';
import Music from './Music';
import About from './About';
import Banner from './Imgs/profile_banner.png'
import PixelArtBtn from './Imgs/pixelArtButton.png'
import GamesBtn from './Imgs/GamesButton.png'
import AboutMeBtn from './Imgs/AboutMeButton.png'
import MusicBtn from './Imgs/MusicButton.png'
import Footer from './Footer';
import './styles.css';

export default function Portfolio() {
    const [activeComponent, setActiveComponent] = useState('PixelArt');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'PixelArt':
                return <PixelArt />;
            case 'Games':
                return <Games />;
            case 'Music':
                return <Music />;
            case 'About':
                return <About />;
            default:
                return <PixelArt />;
        }
    };

    return (
        <div>
            <img className='banner' src={Banner} alt="Banner"></img>
            <nav className="navbar">
            <ul>
                    <li
                        onClick={() => setActiveComponent('PixelArt')}
                        className={activeComponent === 'PixelArt' ? 'nav-btn selected' : 'nav-btn'}
                    >
                        <img src={PixelArtBtn} alt="PixelArt" />
                    </li>
                    <li
                        onClick={() => setActiveComponent('Games')}
                        className={activeComponent === 'Games' ? 'nav-btn selected' : 'nav-btn'}
                    >
                        <img src={GamesBtn} alt="Games" />
                    </li>
                    <li
                        onClick={() => setActiveComponent('Music')}
                        className={activeComponent === 'Music' ? 'nav-btn selected' : 'nav-btn'}
                    >
                        <img src={MusicBtn} alt="Music" />
                    </li>
                    <li
                        onClick={() => setActiveComponent('About')}
                        className={activeComponent === 'About' ? 'nav-btn selected' : 'nav-btn'}
                    >
                        <img src={AboutMeBtn} alt="About" />
                    </li>
                </ul>
            </nav>
            <div className="component-container">
                {renderComponent()}
            </div>

            <Footer />
        </div>
    );
}
