import React from 'react';
import "./index.css";
import ProfilePic from './Imgs/ProfilePic.jpeg'

export default function About() {
    return(

        <div>
            <table className="games-table">
                <tbody>
                    <tr>
                        <td className="games-text-cell">
                            <p>I'm a Bolivian-Chilean pixel-artist, game developer, musician, singer-songwriter, and pixel artist. I've been working as a full-stack developer from 2021 and I'm currently working on "El Salar", an adventure game inspired by bolivian folklore.</p>
                        </td>
                        <td className="games-image-cell">
                            <img className="games-image" src={ProfilePic} alt="That's me"></img>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p></p>
        </div>
    )
}